import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/api-services/api.service';
import { SharedService } from 'src/app/services/shared.service';
import { Appreciate, setAppreciate } from 'src/app/state/appreciate/appreciate.actions';
import {
  isAppreciateEnabled,
  isLeaderboardEnabled,
  isNominationEnabled,
  isPostPollEnabled,
  isRewardEnabled,
} from 'src/app/utility/common-logic';
import { FeedType } from 'src/app/utility/enum';
import { AuthData } from 'src/app/utility/model/token-auth';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bottom-menu',
  templateUrl: './bottom-menu.component.html',
  styleUrls: ['./bottom-menu.component.scss'],
})
export class BottomMenuComponent {
  modules = environment.settings.modules;
  showActive = environment.settings.showActiveDotInBottomMenu;
  cartDetails: any;
  cartCount: number;
  to: string = 'home';
  moreMenu = inject(NgbModal);
  moreButtonHidden: boolean = environment.settings.showMoreButtonInBottomMenu;
  leftNavigate = environment.settings.leftNavigate;
  authData: Partial<AuthData> = {};
  feeds = environment.settings.feeds;
  bottomMenuShow = [];
  flexiBenefitLink = '';

  constructor(
    private router: Router,
    private sharedService: SharedService,
    private activatedRoute: ActivatedRoute,
    private appreciateStore: Store<{ appreciate: Appreciate }>,
    private authDataStore: Store<{ authData: AuthData }>,
    private translate: TranslateService,
    private api: ApiService,
  ) {}

  async ngOnInit() {
    this.activatedRoute.url.subscribe((url: UrlSegment[]) => {
      this.to = url.length > 0 ? url[0].path : this.router.url.split('/')[1];
    });
    if (this.modules.cart) {
      this.sharedService.cartCount.subscribe((count) => {
        this.cartCount = count;
      });
    }

    this.authDataStore
      .select('authData')
      .subscribe((res: Partial<AuthData>) => {
        this.authData = res;
        if (
          (isAppreciateEnabled(this.authData) &&
            isNominationEnabled(this.authData)) ||
          isAppreciateEnabled(this.authData)
        ) {
          this.addLink(isAppreciateEnabled(this.authData), 'appreciate', [
            'home',
            'feeds',
          ]);
          if (this.leftNavigate.includes('nomination')) {
            let index = this.leftNavigate.findIndex((x) => x === 'nomination');
            if (index) {
              this.leftNavigate.splice(index, 1);
            }
          }
        } else if (isNominationEnabled(this.authData)) {
          this.addLink(isNominationEnabled(this.authData), 'nomination', [
            'home',
            'feeds',
          ]);
        }
        this.addLink(this.isCalendarEnabled, 'calendar', [
          'home',
          'feeds',
          'rewards',
          'receipts',
        ]);

        this.bottomMenu();
      });
  }

  get isCalendarEnabled() {
    return (
      this.authData?.tokenAuth?.organization_settings?.show_events !== 0 ||
      this.authData?.tokenAuth?.organization_settings?.show_greetings !== 0
    );
  }

  addLink(isEnabled: boolean, link: string, prevLinks: string[]) {
    if (!isEnabled || this.leftNavigate?.includes(link)) {
      return;
    }

    let index = -1;
    for (let i = prevLinks?.length - 1; i >= 0; i--) {
      if (this.leftNavigate?.includes(prevLinks[i])) {
        index = this.leftNavigate.findIndex((x) => x === prevLinks[i]);
        break;
      }
    }
    if (index > -1) {
      this.leftNavigate.splice(index, 0, link);
    }
  }

  is(to: string) {
    return (
      (to === 'my-wallet' && to === this.router.url.split('/')[2]) ||
      (to === 'appreciate' && to === this.router.url.split('/')[1]) ||
      (to === 'receipt-list' && to === this.router.url.split('/')[1]) ||
      (to === 'sales-receipt' &&
        this.router.url.split('/')[1] === 'sales-receipt-list') ||
      this.to === to
    );
  }

  selectedClass(to: string) {
    return this.is(to) ? 'selected' : '';
  }

  fontClass(to: string) {
    return this.to === to ? 'font-selected' : 'font-unselected';
  }
  onClickTopMenu(menu: string) {
    sessionStorage.setItem('selected-topMenu', menu);
    this.to = menu;
    this.redirectTo(this.link(menu))
  }

  openModal(content) {
    this.moreMenu.open(content, {
      windowClass: 'modal-bottom-right',
      backdrop: true,
    });
  }

  get bottomMenus() {
    if (this.bottomMenuShow && this.bottomMenuShow.length <= 5) {
      return this.bottomMenuShow;
    } else if (this.bottomMenuShow && this.bottomMenuShow.length > 5) {
      return this.bottomMenuShow.slice(0, 4);
    } else {
      return [];
    }
  }

  get moreMenus() {
    return this.bottomMenuShow.slice(4);
  }

  link(code) {
    if (code === 'appreciate') {
      return ['/appreciate'];
    }
    if (code === 'nomination') {
      return ['/appreciate'];
    }

    if (code === 'feeds') {
      if (
        !isAppreciateEnabled(this.authData) &&
        !isNominationEnabled(this.authData)
      ) {
        return ['/' + this.feeds.path, 'post-n-polls'];
      }
      return ['/' + this.feeds.path, 'appreciation'];
    }

    if (code === 'rewards') {
      return ['/rewards'];
    }
    if (code === 'my-wallet') {
      return ['/rewards/my-wallet'];
    }
    if (code === 'calendar') {
      return ['/calendar'];
    }
    if (code === 'settings') {
      return ['/settings'];
    }
    if (code === 'receipts') {
      return ['/receipt-list'];
    }
    if (code === 'sales-receipts') {
      return ['/sales-receipt-list'];
    }
    if (code === 'leaderboard') {
      return ['/leaderboard'];
    }
    if (code === 'flexiBenefits') {
      this.flexiBenefitLink = this.authData?.dashboard?.flexi_benefits_url;
      return [this.flexiBenefitLink];
    }
    return ['/home'];
  }

  label(code) {
    if (code === 'home') {
      return this.translate.instant('common.home');
    }
    if (code === 'nomination') {
      return this.translate.instant('common.nomination');
    }
    if (code === 'feeds') {
      return this.translate.instant(this.feeds.label);
    }
    if (code === 'rewards') {
      return this.translate.instant('common.reward.n');
    }
    if (code === 'my-wallet') {
      return this.translate.instant('common.myWallet');
    }
    if (code === 'calendar') {
      return this.translate.instant('common.calendar');
    }
    if (code === 'settings') {
      return this.translate.instant('common.settings');
    }
    if (code === 'receipts') {
      return this.translate.instant('common.receipts');
    }
    if (code === 'sales-receipts') {
      return this.translate.instant('common.receipts');
    }
    if (code === 'leaderboard') {
      return this.translate.instant('common.leaderboard');
    }
    if (code === 'flexiBenefits') {
      return this.translate.instant('common.flexiBenefits');
    }

    if (code === 'point-history') {
      return this.translate.instant('common.pointsHistory');
    }

    if (code == 'appreciate') {
      return this.translate.instant('common.appreciate');
    }
    return '';
  }

  bottomMenu() {
    this.bottomMenuShow = [];
    this.leftNavigate?.forEach((item) => {
      if (item === 'home') {
        this.bottomMenuShow.push('home');
      } else if (
        item === 'feeds' &&
        (isAppreciateEnabled(this.authData) ||
          isPostPollEnabled(this.authData) ||
          isNominationEnabled(this.authData))
      ) {
        this.bottomMenuShow.push('feeds');
      } else if (item === 'rewards' && isRewardEnabled(this.authData)) {
        this.bottomMenuShow.push('rewards');
      } else if (item === 'my-wallet') {
        this.bottomMenuShow.push('my-wallet');
      } else if (item === 'calendar') {
        this.bottomMenuShow.push('calendar');
      } else if (item === 'settings') {
        this.bottomMenuShow.push('settings');
      } else if (
        (item === 'appreciate' && isAppreciateEnabled(this.authData)) ||
        (item === 'nomination' && isNominationEnabled(this.authData))
      ) {
        this.bottomMenuShow.push('appreciate');
      } else if (
        this.authData?.tokenAuth?.organization_settings
          ?.enable_benefit_receipts_upload === 1 &&
        this.authData?.tokenAuth?.organization_settings
          ?.enable_receipts_upload === 0 &&
        item === 'receipts'
      ) {
        this.bottomMenuShow.push('receipts');
      } else if (
        this.authData?.tokenAuth?.organization_settings
          ?.enable_receipts_upload === 1 &&
        item === 'sales-receipts'
      ) {
        this.bottomMenuShow.push('sales-receipts');
      } else if (
        item === 'leaderboard' &&
        isLeaderboardEnabled(this.authData)
      ) {
        this.bottomMenuShow.push('leaderboard');
      } else if (
        this.authData?.dashboard?.flexi_benefits_url &&
        item === 'flexiBenefits'
      ) {
        this.bottomMenuShow.push('flexiBenefits');
      }
    });
  }

  async redirectTo(link) {
    if (link[0].startsWith('https')) {
      if (this.link[0] === this.flexiBenefitLink) {
        this.getDeepLinkToken();
      } else {
        window.open(this.link[0], '_blank');
      }
    } else {
      if (link === 'nomination') {
        this.appreciateStore.dispatch(
          setAppreciate({ mode: FeedType.nomination }),
        );
        await this.router.navigate(['/appreciate/nom-cat']);
      } else {
        await this.router.navigate(link);
      }
    }
  }

  getDeepLinkToken() {
    this.api.getDeepLinkToken().then((res) => {
      if (res?.deeplink_token) {
        window.open(
          this.flexiBenefitLink + '/auto-login?token=' + res.deeplink_token,
          '_blank',
        );
      }
    });
  }
}
